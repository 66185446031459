import { Alert, Modal, ConfigProvider, theme, Skeleton } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import * as lineupActions from '../../actions/lineup'
import * as accountActions from '../../actions/account'
import * as groupActions from '../../actions/groups'
import * as analysisActions from '../../actions/analysis'
import * as adminActions from '../../actions/admin'
import * as afterStartActions from '../../actions/after-start'
import { EXP_SETTINGS_KEY } from '../../constants/local-storage-keys'
import { getTeamsInSlate } from '../../utils/slate'
import { getLineupSize } from '../../utils/contests'
import getDefaultDateInfo from '../../utils/get-default-date-info'
import { POSITIONLESS_SPORTS } from '../../constants/positionless'

import { framed, overrideBackground, overridePrimary, overrideFont, customPresets, allowCheatsheets } from '../../constants/partner'

const GeneralOptimizer = React.lazy(() => import('../general'))
import PositionalOptimizer from '../positional'

import ReactGA from 'react-ga'
import './lineup-generator.css'
import { isPlayerTableDefault } from '../../utils/is-default'

ReactGA.initialize('UA-146608705-1')

const betweenWeeks = false
const update = false

const MaintenenceText = styled.div`
  color: #FF9966;
  text-align: center;
  font-size: 18px;
  margin-top: 2%;
  margin-bottom: 2%;
`

const StyledLineupGenerator = styled.div`
  padding: ${framed ? '10px 2% 76px' : '100px 2% 76px'};
`

class LineupGenerator extends Component {
  state = {
    tab: 'players',
    slate: 'Main',
    site: 'dk',
    sport: '',
    contestType: 'classic',
    defaultModalOpen: false
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search)
    let counter = params.get('date')
    let season = params.get('season')
    let site = params.get('site') || this.state.site
    let slate = params.get('slate') || this.state.slate
    let sport = params.get('sport') || this.state.sport
    let contestType = params.get('contestType') || this.state.contestType

    if (!counter || !season) {
      let [_counter, _season] = getDefaultDateInfo(sport)
      counter = counter || _counter
      season = season || _season
    }


    // forward Main to showdown for superbowl
    if (sport === 'nfl' && Number(counter) === 22 && contestType !== 'showdown') {
      contestType = 'showdown'
    }

    this.props.fetchSubscription({ sport })
    
    // set 
    this.setState({
      counter,
      season,
      site,
      slate,
      sport,
      contestType
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { site, slate, sport, contestType } = this.state
    const positionless = POSITIONLESS_SPORTS.indexOf(sport) >= 0

    let showdown = contestType === 'showdown'
    // load player table if new w
    if (
      prevState.counter !== this.state.counter || 
      prevState.season !== this.state.season ||
      prevState.site !== this.state.site ||
      prevState.slate !== this.state.slate ||
      prevState.sport !== this.state.sport
    ) {
      this.props.fetchGroups({site, slate, sport, counter: this.state.counter, season: this.state.season})
      this.props.fetchSettings({ site, sport, showdown, slate, counter: this.state.counter, season: this.state.season })
      this.props.fetchPlayers({slate, site, sport, showdown, counter: this.state.counter, season: this.state.season})
      this.props.fetchLineupRuns({slate, site, sport, counter: this.state.counter, season: this.state.season})
      this.props.fetchSimSlates({slate, site, sport, counter: this.state.counter, season: this.state.season})
      if (allowCheatsheets)
        this.props.fetchCheatsheets({ site, slate, sport, counter: this.state.counter, season: this.state.season })
    }

    if (this.props.opt.loading && !prevProps.opt.loading) {
      this.setState({
        tab: 'lineups'
      })
    }

    if (prevProps.players.loading && !this.props.players.loading) {
      if (!positionless) {
        this.props.fetchTeamStacks({
          players: this.props.players.data,
          slate,
          site,
          sport,
          counter: this.state.counter,
          season: this.state.season
        })
      }

      const numTeams = getTeamsInSlate(this.props.players.data) || 24
      if (sport === 'nfl')
        this.props.fetchPositionCounts({ numLUs: this.props.settings.data.numLUs, numTeams })
      if (contestType !== 'showdown' || site === 'dk')
        this.props.fetchPresets({ contestType, numTeams, site, sport })

      // only fetch if we are doing custom
      if (customPresets)
        this.props.fetchCustomPresets({ site, slate, sport, counter: this.state.counter, season: this.state.season })
    }

    // Fetch new position counts when settings are loaded
    if (prevProps.settings.loading && !this.props.settings.loading && sport === 'nfl') {
      const numTeams = getTeamsInSlate(this.props.players.data) || 24
      this.props.fetchPositionCounts({ numLUs: this.props.settings.data.numLUs, numTeams })
    }

    // Fetch new position counts when num lus is changed
    if (prevProps.settings.data.numLUs !== this.props.settings.data.numLUs && sport === 'nfl') {
      const numTeams = getTeamsInSlate(this.props.players.data) || 24
      this.props.fetchPositionCounts({ numLUs: this.props.settings.data.numLUs, numTeams })
    }
  }

  updateSlate(slate, season, counter) {
    const [ slateType, slateName ] = slate.split("|")

    this.props.resetLineupRuns()
    this.props.clearAfterStart()

    this.setState({
      slate: slateName,
      contestType: slateType,
      tab: 'players'
    })
  }

  updateSite(site) {
    const { sport, counter } = this.state

    const slate = 'Main'
    let contestType = 'classic'
    // if super bowl use showdown for Main
    if (sport === 'nfl' && Number(counter) === 22) {
      contestType = 'showdown'
    }

    this.props.resetLineupRuns()
    this.props.clearAfterStart()
    
    this.setState({
      site: site,
      slate: slate,
      contestType: contestType,
      tab: 'players'
    })
  }

  clearPlayerData() {
    const { site, slate, sport, contestType } = this.state
    let showdown = contestType === 'showdown'
    // Reset default exposures to 0/100
    localStorage.setItem(EXP_SETTINGS_KEY, JSON.stringify({
      zeroed: false
    }))
    // First remove all user settings
    this.props.removePlayers({slate, site, sport, counter: this.state.counter, season: this.state.season})
    // Re-fetch players
    this.props.fetchPlayers({slate, site, sport, showdown, counter: this.state.counter, season: this.state.season})
  }

  zeroExposures = () => {
    const { site, slate, sport, contestType } = this.state
    let showdown = contestType === 'showdown'

    if (this.props.loggedin) {
      // Set default exposures to 0/0
      localStorage.setItem(EXP_SETTINGS_KEY, JSON.stringify({
        zeroed: true
      }))

      this.props.fetchPlayers({slate, site, sport, showdown, counter: this.state.counter, season: this.state.season})
    }
    else {
      const _players = this.props.players

      const _playersCleared = _players.data.map(player => {
        const _player = { ...player }
        _player.MinExp = 0
        _player.MaxExp = 0
        return _player
      })

      this.props.updatePlayersTable({payload: _playersCleared, slate, site, sport, counter: this.state.counter, season: this.state.season})
    }
  }

  clearSettingsData() {
    const { site, slate, sport } = this.state

    this.props.removeTeamStacks({ slate, site, sport, counter: this.state.counter, season: this.state.season })
  }

  getLineups() {
    const { settings, players } = this.props

    // Check to see if there are sufficient changes (per DK guidelines)
    const playerTableDefault = isPlayerTableDefault(players.data)

    if (playerTableDefault) {
      this.setState({defaultModalOpen: true})
    } else {
      const { site, slate, sport } = this.state
      let contestType = this.state.contestType || 'classic'

      // Check to see if we have no ownership
      let ownership = false
      this.props.players.data.forEach(p => {
        if (Number(p.ProjOwn || 0) !== 0 || Number(p.UserOwn || 0) !== 0) {
          ownership = true
          return
        }
      })

      let lineupLength = getLineupSize(site, contestType, sport)

      let overrides = {}
      if (!ownership) {
        overrides = {
          minUnderOwn_1: 0,
          minUnderOwn_2: 0,
          minUnderOwn_3: 0,
          minUnderOwn_4: 0,
          minUnderOwn_5: 0,
          maxUnderOwn_1: lineupLength,
          maxUnderOwn_2: lineupLength,
          maxUnderOwn_3: lineupLength,
          maxUnderOwn_4: lineupLength,
          maxUnderOwn_5: lineupLength,
          minTotalOwn: 0,
          maxTotalOwn: (lineupLength * 100)
        }
      }

      const params = { 
        ...settings.data, 
        slate, 
        site, 
        sport, 
        contestType, 
        lineupLength,
        counter: this.state.counter,
        season: this.state.season,
        ...overrides 
      }

      this.props.fetchOpt(params)
    }
  }

  changeTab(tab) {
    this.setState({
      tab
    })
  }

  changeDateInfo({counter, season}) {
    const { sport, contestType } = this.state
    let newContestType
    // forward Main to showdown for superbowl
    if (sport === 'nfl' && Number(counter) === 22 && contestType !== 'showdown') {
      newContestType = 'showdown'
    } else if (sport === 'nfl' && Number(counter) !== 22 && contestType === 'showdown') {
      newContestType = 'classic'
    }

    this.props.resetLineupRuns()
    this.props.clearAfterStart()

    this.setState({
      counter,
      season,
      contestType: newContestType || contestType,
      slate: 'Main',
      tab: 'players'
    })
  }

  render() {
    const { opt, players, settings } = this.props

    if (!players.data || !settings.data) return false

    const { tab } = this.state
    const { site, slate, sport, contestType } = this.state

    let body

    switch (sport) {
      case 'nfl':
        body = (
          <React.Suspense fallback={<Skeleton />}>
            <PositionalOptimizer
              players={players}
              clearPlayerData={this.clearPlayerData.bind(this)}
              zeroExposures={this.zeroExposures.bind(this)}
              settings={settings}
              updateSlate={this.updateSlate.bind(this)}
              clearStackSettings={this.clearSettingsData.bind(this)}
              changeTab={this.changeTab.bind(this)}
              getLineups={this.getLineups.bind(this)}
              showdown={contestType === 'showdown'}
              opt={opt}
              slate={slate}
              site={site}
              tab={tab}
              counter={this.state.counter}
              season={this.state.season}
              changeDateInfo={this.changeDateInfo.bind(this)}
              sport={sport}
              updateSite={this.updateSite.bind(this)}
            />
          </React.Suspense>
        )
        break
      case 'nba':
        body = (
          <React.Suspense fallback={<Skeleton />}>
            <PositionalOptimizer
              players={players}
              clearPlayerData={this.clearPlayerData.bind(this)}
              zeroExposures={this.zeroExposures.bind(this)}
              settings={settings}
              updateSlate={this.updateSlate.bind(this)}
              clearStackSettings={this.clearSettingsData.bind(this)}
              changeTab={this.changeTab.bind(this)}
              getLineups={this.getLineups.bind(this)}
              showdown={contestType === 'showdown'}
              opt={opt}
              slate={slate}
              site={site}
              tab={tab}
              sport={sport}
              updateSite={this.updateSite.bind(this)}
              season={this.state.season}
              counter={this.state.counter}
              changeDateInfo={this.changeDateInfo.bind(this)}
            />
          </React.Suspense>
        )
        break
      case 'mlb':
        body = (
          <React.Suspense fallback={<Skeleton />}>
            <PositionalOptimizer
              players={players}
              clearPlayerData={this.clearPlayerData.bind(this)}
              zeroExposures={this.zeroExposures.bind(this)}
              settings={settings}
              updateSlate={this.updateSlate.bind(this)}
              clearStackSettings={this.clearSettingsData.bind(this)}
              changeTab={this.changeTab.bind(this)}
              getLineups={this.getLineups.bind(this)}
              showdown={contestType === 'showdown'}
              opt={opt}
              slate={slate}
              site={site}
              tab={tab}
              counter={this.state.counter}
              season={this.state.season}
              changeDateInfo={this.changeDateInfo.bind(this)}
              sport={sport}
              updateSite={this.updateSite.bind(this)}
            />
          </React.Suspense>
        )
        break
      default:
        body = (
          <React.Suspense fallback={<Skeleton />}>
            <GeneralOptimizer
              players={players}
              clearPlayerData={this.clearPlayerData.bind(this)}
              zeroExposures={this.zeroExposures.bind(this)}
              settings={settings}
              updateSlate={this.updateSlate.bind(this)}
              clearStackSettings={this.clearSettingsData.bind(this)}
              changeTab={this.changeTab.bind(this)}
              getLineups={this.getLineups.bind(this)}
              showdown={contestType === 'showdown'}
              opt={opt}
              slate={slate}
              site={site}
              tab={tab}
              sport={sport}
              updateSite={this.updateSite.bind(this)}
              season={this.state.season}
              counter={this.state.counter}
              changeDateInfo={this.changeDateInfo.bind(this)}
            />
          </React.Suspense>
        )
        break
    }

    return (
      <ConfigProvider
        theme={{
          algorithm: theme.darkAlgorithm,
          token: {
            colorPrimary: overridePrimary,
            colorBgBase: overrideBackground,
            fontFamily: overrideFont,
          }
          
        }}
      >
        <StyledLineupGenerator className="LineupGenerator">
          <Modal
              title="Player Table must be modified!"
              top
              open={this.state.defaultModalOpen}
              onOk={() => {this.setState({defaultModalOpen: false})}}
              onCancel={() => {this.setState({defaultModalOpen: false})}}
              footer={null}
            >
              <p>
                  Community Guidelines require users to modify their player table before using a lineup building tool. <br/><br/>
                  You can do this by: <br/><br/>
                  - Adjusting a player's exposure<br/>
                  - Adjusting a player's projected point total<br/>
                  - Adjusting a player's projected ownership<br/><br/>
                  Thank you for your understanding!
              </p>
            </Modal>
          <div className="LineupGenerator-body">
            { body }
          </div>
        </StyledLineupGenerator>
      </ConfigProvider>
    )
  }
}

export default connect(
  state => ({
    opt: state.lineup.opt,
    players: state.lineup.players,
    settings: state.lineup.settings,
    teamStacks: state.lineup.teamStacks,
    savedLineups: state.lineup.savedLineups,
    loggedin: state.auth.loggedin,
    socket: state.sockets,
    subscription: state.subscription
  }),
  {
    fetchOpt: lineupActions.fetchOpt,
    clearOpt: lineupActions.clearOpt,
    fetchPlayers: lineupActions.fetchPlayers,
    fetchTeamStacks: lineupActions.fetchTeamStacks,
    fetchSettings: lineupActions.fetchSettings,
    fetchPresets: lineupActions.fetchPresets,
    fetchCustomPresets: adminActions.fetchCustomPresets,
    removePlayers: lineupActions.removePlayers,
    cancelOpt: lineupActions.cancelOpt,
    fetchSavedLineups: lineupActions.fetchSavedLineups,
    updatePlayersTable: lineupActions.updatePlayersTable,
    removeTeamStacks: lineupActions.removeTeamStacks,
    fetchSubscription: accountActions.fetchSubscription,
    fetchGroups: groupActions.fetchGroups,
    fetchPositionCounts: analysisActions.fetchPositionCounts,
    fetchCheatsheets: adminActions.fetchCheatsheets,
    fetchLineupRuns: lineupActions.fetchLineupRuns,
    resetLineupRuns: lineupActions.resetLineupRuns,
    fetchSimSlates: lineupActions.fetchSimSlates,
    clearAfterStart: afterStartActions.clearAfterStart
  }
)(LineupGenerator)
